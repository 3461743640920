import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import Seo from './../components/seo/seo'
import Layout from './../components/layout/Layout'

const Careers = () => {
  useEffect(() => {
    setTimeout(() => {
      window.AOS.init({
        duration: 1200,
        easing: 'ease-out-back',
      })
      window.jQuery('.photo-gallery').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        centerMode: true,
        variableWidth: true,
      })

      window.jQuery(function () {
        window.jQuery('.open-photo-gallery').click(function () {
          window.jQuery('body').addClass('active')
        })
        window.jQuery('.photo-gallery-modal-close').click(function () {
          window.jQuery('body').removeClass('active')
        })
      })
      window.TweenLite.defaultEase = window.Linear.easeNone
      var titles = document.querySelectorAll('.sectionTitle')
      var controller = new window.ScrollMagic.Controller()
      var tl = new window.TimelineMax()

      // create timeline
      // this could also be created in a loop
      if (window.$(window).width() >= 1024) {
        tl.to('#js-slideContainer', 1, { xPercent: -5 }, 'label1')
        tl.from(titles[1], 0.3, { opacity: 1 }, 'label1+=0.3')
        tl.to('#js-slideContainer', 1, { xPercent: -10 }, 'label2')
        tl.from(titles[2], 0.3, { opacity: 1 }, 'label2+=0.3')
        tl.to('#js-slideContainer', 1, { xPercent: -15 }, 'label3')
        tl.from(titles[3], 0.3, { opacity: 1 }, 'label3+=0.3')
        tl.to('#js-slideContainer', 1, { xPercent: -20 }, 'label4')
        tl.from(titles[4], 0.3, { opacity: 1 }, 'label4+=0.3')
        tl.to('#js-slideContainer', 1, { xPercent: -20 }, 'label5')
        tl.from(titles[5], 0.3, { opacity: 1 }, 'label5+=0.3')
        new window.ScrollMagic.Scene({
          triggerElement: '#js-wrapper',
          triggerHook: 'onLeave',
          duration: '400%',
        })
          .setPin('#js-wrapper')
          .setTween(tl)
          .addTo(controller)
      }
    }, 1000)
  }, [])

  return (
    <>
      <Seo
        title={'Careers | immence'}
        description={'We are always in search of talented people.'}
      />
      <Layout>
        <div>
          <section className="content-page bg-service" id="service_page">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="content_padd">
                    <div className="row">
                      <h2 className="hero-heading">
                        Talents <br />
                        <span className="blue-span"> wanted </span>
                      </h2>
                    </div>
                    <div className="row">
                      <p className="section-main-para heading-setting mb-0">
                        We are always in search of talented people. If you are
                        committed to excellence and initiative, you are the
                        person for us and we are the company for you.
                      </p>
                    </div>
                    {/* <a href="#open-job-list" class="btn btn-outline-dark-primary btn-ouline mt-5">View Openings</a> */}
                  </div>
                </div>
                <div className="col-lg-6 center-item">
                  <div className="service_img">
                    <img
                      src={require('../img/heroImg/careers.png').default}
                      alt="insights"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="com-section open-postion-section"
            id="open-job-list"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="hero-heading offer-section-heading text-center">
                    Open Positions
                  </h2>
                </div>
                <div className="col-lg-12">
                  <div className="open-job-list">
                    <div className="row">
                      <div className="col-lg-8 col-md-12 mx-auto">
                        <div className="job-list" data-aos="fade-up">
                          <Link
                            to="/careers/flutter-developer"
                            className="job-list-a"
                          >
                            <div className="job-wrap">
                              <h2 className="job-title">Flutter Developer</h2>
                              <div className="d-flex">
                                <div className="job-type me-2">
                                  <p>Full time</p>
                                </div>
                                <div className="exp-yrs">
                                  <span>•</span>
                                  <p>1-3 Years</p>
                                </div>
                              </div>
                            </div>
                            <div className="apply-btn">
                              <p>
                                Apply now
                                {/* <i class="fi-rr-arrow-right"></i> */}
                              </p>
                            </div>
                          </Link>
                        </div>
                        <div className="job-list" data-aos="fade-up">
                          <a
                            href="/careers/reactjs-developer"
                            className="job-list-a"
                          >
                            <div className="job-wrap">
                              <h2 className="job-title">React JS Developer</h2>
                              <div className="d-flex">
                                <div className="job-type me-2">
                                  <p>Full time</p>
                                </div>
                                <div className="exp-yrs">
                                  <span>•</span>
                                  <p>0-3 Years</p>
                                </div>
                              </div>
                            </div>
                            <div className="apply-btn">
                              <p>
                                Apply now
                                {/* <i class="fi-rr-arrow-right"></i> */}
                              </p>
                            </div>
                          </a>
                        </div>
                        <div className="job-list" data-aos="fade-up">
                          <a
                            href="/careers/nodejs-developer"
                            className="job-list-a"
                          >
                            <div className="job-wrap">
                              <h2 className="job-title">Node.js Developer</h2>
                              <div className="d-flex">
                                <div className="job-type me-2">
                                  <p>Full time</p>
                                </div>
                                <div className="exp-yrs">
                                  <span>•</span>
                                  <p>1-5 Years</p>
                                </div>
                              </div>
                            </div>
                            <div className="apply-btn">
                              <p>
                                Apply now
                                {/* <i class="fi-rr-arrow-right"></i> */}
                              </p>
                            </div>
                          </a>
                        </div>
                        <div className="job-list" data-aos="fade-up">
                          <a
                            href="/careers/reactnative-developer"
                            className="job-list-a"
                          >
                            <div className="job-wrap">
                              <h2 className="job-title">
                                React Native Developer
                              </h2>
                              <div className="d-flex">
                                <div className="job-type me-2">
                                  <p>Full time</p>
                                </div>
                                <div className="exp-yrs">
                                  <span>•</span>
                                  <p>1-5 Years</p>
                                </div>
                              </div>
                            </div>
                            <div className="apply-btn">
                              <p>
                                Apply now
                                {/* <i class="fi-rr-arrow-right"></i> */}
                              </p>
                            </div>
                          </a>
                        </div>
                        <div className="job-list" data-aos="fade-up">
                          <a
                            href="/careers/ruby-on-rails-developer"
                            className="job-list-a"
                          >
                            <div className="job-wrap">
                              <h2 className="job-title">
                                Ruby on Rails Developer
                              </h2>
                              <div className="d-flex">
                                <div className="job-type me-2">
                                  <p>Full time</p>
                                </div>
                                <div className="exp-yrs">
                                  <span>•</span>
                                  <p>2-5 Years</p>
                                </div>
                              </div>
                            </div>
                            <div className="apply-btn">
                              <p>
                                Apply now
                                {/* <i class="fi-rr-arrow-right"></i> */}
                              </p>
                            </div>
                          </a>
                        </div>
                        <div className="job-list" data-aos="fade-up">
                          <a
                            href="/careers/business-development-executive"
                            className="job-list-a"
                          >
                            <div className="job-wrap">
                              <h2 className="job-title">
                                Business Development Executive
                              </h2>
                              <div className="d-flex">
                                <div className="job-type me-2">
                                  <p>Full time</p>
                                </div>
                                <div className="exp-yrs">
                                  <span>•</span>
                                  <p>1-5 Years</p>
                                </div>
                              </div>
                            </div>
                            <div className="apply-btn">
                              <p>
                                Apply now
                                {/* <i class="fi-rr-arrow-right"></i> */}
                              </p>
                            </div>
                          </a>
                        </div>
                        <div className="job-list" data-aos="fade-up">
                          <a
                            href="/careers/ui-ux-designer"
                            className="job-list-a"
                          >
                            <div className="job-wrap">
                              <h2 className="job-title">UI/UX Designer</h2>
                              <div className="d-flex">
                                <div className="job-type me-2">
                                  <p>Full time</p>
                                </div>
                                <div className="exp-yrs">
                                  <span>•</span>
                                  <p>0-1 Years</p>
                                </div>
                              </div>
                            </div>
                            <div className="apply-btn">
                              <p>
                                Apply now
                                {/* <i class="fi-rr-arrow-right"></i> */}
                              </p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export default Careers
